import React, { useEffect, useState } from "react";
import {
  Collapse,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  message,
  Select,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Request from "../../common/Request";

const { Panel } = Collapse;
const { confirm } = Modal;
const { Option } = Select;

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentFaqIndex, setCurrentFaqIndex] = useState(null);
  const [loader, setLoader] = useState(true);
  const [form] = Form.useForm();

  const showAddModal = () => {
    form.resetFields();
    setIsEditMode(false);
    setIsModalVisible(true);
  };

  const showEditModal = (index) => {
    setIsEditMode(true);
    setCurrentFaqIndex(index);
    form.setFieldsValue(faqs[index]);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (isEditMode) {
          addFaq(values, true);
        } else {
          addFaq(values);
        }
        form.resetFields();
        setIsModalVisible(false);
        setCurrentFaqIndex(null);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeleteConfirm = (index) => {
    confirm({
      title: "Are you sure you want to delete this FAQ?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await Request({
            method: "delete",
            url: `superuser/faq/${faqs[index].id}/`,
          });
          message.success("Deleted Successfully");
          getFaqList();
        } catch (error) {
          message.error("something went wrong");
        }
      },
    });
  };

  const addFaq = async (values, edit = false) => {
    try {
      if (edit) {
        await Request({
          method: "put",
          url: `superuser/faq/${faqs[currentFaqIndex].id}/`,
          data: { ...values, language: values.language === "en" ? 1 : 2 },
        });
      } else {
        await Request({
          method: "post",
          url: "superuser/faq/",
          data: { ...values, language: values.language === "en" ? 1 : 2 },
        });
      }
      getFaqList();
      message.success("FAQ Saved Successfully");
    } catch (err) {
      message.error("Failed to save FAQ");
    }
  };

  const getFaqList = async () => {
    setLoader(true);
    try {
      const res = await Request({ method: "get", url: "superuser/faq/" });
      const data = res.data.map((item) => {
        return {
          ...item,
          language: item.language === 1 ? "en" : "de",
        };
      });
      setFaqs(data);
    } catch (err) {
      message.error("Failed to load FAQs");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <div style={{ padding: "10px", borderRadius: "8px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2>FAQs</h2>
        <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal}>
          Add FAQ
        </Button>
      </div>
      {loader ? (
        <Spin />
      ) : faqs.length ? (
        <Collapse
          defaultActiveKey={["0"]}
          accordion
          bordered={false}
          style={{ background: "#f0f2f5", borderRadius: "8px" }}
        >
          {faqs.map((faq, index) => (
            <Panel
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: -10,
                  }}
                >
                  <p>
                    <strong>Q{index + 1}: </strong>{" "}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                      {faq.question}
                    </span>
                  </p>
                  <div>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => showEditModal(index)}
                      style={{ marginRight: "8px", color: "#1890ff" }}
                    />
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => showDeleteConfirm(index)}
                      style={{ color: "#ff4d4f" }}
                    />
                  </div>
                </div>
              }
              key={index}
            >
              <p>
                <strong>Answer:</strong> {faq.answer}
              </p>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <h3 style={{ color: "gray" }}>No FAQs Found!</h3>
      )}
      <Modal
        title={isEditMode ? "Edit FAQ" : "Add a New FAQ"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isEditMode ? "Save" : "Add"}
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
        <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true, message: "Please select a language!" }]}
          >
            <Select placeholder="Select language">
              <Option value="en">English</Option>
              <Option value="de">German</Option>
              {/* Add more language options as needed */}
            </Select>
          </Form.Item>
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "Please input the question!" }]}
          >
            <Input placeholder="Enter the question" />
          </Form.Item>
          <Form.Item
            name="answer"
            label="Answer"
            rules={[{ required: true, message: "Please input the answer!" }]}
          >
            <Input.TextArea rows={4} placeholder="Enter the answer" />
          </Form.Item>
         
        </Form>
      </Modal>
    </div>
  );
};

export default Faq;
