import React, { useState, useEffect } from "react";
import { Layout, Menu, Avatar, Dropdown, Button, Spin } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  TeamOutlined,
  SecurityScanOutlined,
  PoundCircleOutlined,
  FileProtectOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import ProfileModal from "../pages/AuthPages/ProfileModal";
import Request from "../common/Request";
import SessionExpiredModal from "../common/SessionExpiredModal";

const { Header, Content, Footer, Sider } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [isSessionExpiredModalVisible, setIsSessionExpiredModalVisible] =
    useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setSelectedKey(path);
  }, [location]);

  const fetchUserData = async () => {
    try {
      const res = await Request({
        method: "get",
        url: "superuser/my-profile/",
      });
      console.log(res);
      setUserData(res.data);
    } catch (err) {
      if (err?.code === "token_not_valid")
        setIsSessionExpiredModalVisible(true);
      else setIsSessionExpiredModalVisible(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/login");
  };

  const showProfileModal = () => {
    setIsProfileModalVisible(true);
  };

  const handleProfileModalClose = () => {
    setIsProfileModalVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  const items = [
    { key: "dashboard", label: "User Management", icon: <UserOutlined /> },
    {
      key: "subscription",
      label: "Subscription",
      icon: <PoundCircleOutlined />,
    },
    {
      key: "payments",
      label: "Payments",
      icon: <MoneyCollectOutlined />
    },
    { key: "aboutus", label: "About Us", icon: <ReadOutlined /> },
    { key: "faq", label: "FAQ", icon: <QuestionCircleOutlined /> },
    { key: "contactus", label: "Contact Us", icon: <TeamOutlined /> },
    {
      key: "privacy&policy",
      label: "Privacy & Policy",
      icon: <SecurityScanOutlined />,
    },
    {
      key: "terms&conditions",
      label: "Terms & Conditions",
      icon: <FileProtectOutlined />,
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        className="site-layout-background"
        style={{
          padding: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          background: "#001529",
          zIndex: 1,
        }}
      >
        <div
          onClick={showProfileModal}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <Avatar
            style={{ backgroundColor: "#87d068", marginRight: "8px" }}
            icon={<UserOutlined />}
          />
          {loading ? (
            <Spin style={{ marginLeft: "8px" }} />
          ) : (
            <span
              style={{
                color: "#fff",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {userData?.first_name
                ? `${userData.first_name} ${userData.last_name}`
                : userData?.email}
            </span>
          )}
        </div>
        <Dropdown overlay={menu} placement="bottomRight">
          <Button
            type="text"
            icon={<LogoutOutlined />}
            style={{ fontSize: "1.2rem", color: "#fff" }}
          />
        </Dropdown>
      </Header>
      <Layout>
        {" "}
        {/* Adjust margin for the header height */}
        <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
          <div className="logo" />
          <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
            {items.map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={`/${item.key.toLowerCase()}`}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Content
            style={{
              margin: "16px",
              padding: "24px",
              minHeight: 280,
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Outlet />
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Copy rights reserved ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
      <ProfileModal
        visible={isProfileModalVisible}
        onClose={handleProfileModalClose}
        userData={userData}
        fetchUserData={fetchUserData}
      />
      <SessionExpiredModal
        visible={isSessionExpiredModalVisible}
        onLogout={handleLogout}
      />
    </Layout>
  );
};

export default MainLayout;
