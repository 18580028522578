import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form, Input, Select, InputNumber, message } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Request from "../../common/Request";
import moment from "moment";

const { Option } = Select;
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};
const Subscription = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [loader,setLoader]=useState(false)
  const [btnLoader,setBtnLoader]=useState(false)
  const [editKey,setEditkey]=useState(0)
  const [form] = Form.useForm();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Tokens",
      dataIndex: "tokens",
      key: "tokens",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {   
            setEditingRecord(record);
            setIsModalVisible(true);
            setTimeout(() => {
              form.setFieldsValue(record);
            }, 500);
        
          }}
        />
      ),
    },
  ];

  const handleAddNew = () => {
    setEditingRecord(null);
    form.resetFields();
    setIsModalVisible(true);
  };
  const createSubscription = async (values) => {
    setBtnLoader(true)
    const { name, price, status, tokens, description } =
      values;
    const data = {
      name,
      price,
      status: status === "Active" ? "A" : "I",
      tokens,
      description,
    };
   
    try {
      await Request({ method: "post", url: "superuser/subscriptions/", data });
      getSubscriptionList()
      message.success("Added Successfully..!")
    } catch (err) {
      message.error(err?.detail)
    }
    finally{
      setBtnLoader(false)
      form.resetFields()
    }
  };
  const editSubscription =async (values) => {
    setBtnLoader(true)
    const { name, price, status, tokens, description } =
      values;
    const data = {
      name,
      price,
      status: status === "Active" ? "A" : "I",
      tokens,
      description,
    };
    try {
      await Request({ method: "put", url: `superuser/update-subscriptions/${editingRecord?.id}/`, data });
      getSubscriptionList()
      message.success("Added Successfully..!")
    } catch (err) {
      message.error(err?.detail)
    }
    finally{
      setBtnLoader(false)
      form.resetFields()
    }
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingRecord) editSubscription(values);
        else createSubscription(values);
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log(info);
      })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields()
  };
  const getSubscriptionList = async () => {
    setLoader(true)
    try {
      const res = await Request({
        method: "get",
        url: "superuser/subscriptions/",
      });
      if (res?.data.length) {
        setDataSource(res?.data.map((item)=>{
          return({
            id: item.id,
            name: item.name,
            price: parseFloat(item.price),
            tokens: parseInt(item.tokens),
            description: item.description,
            status: item.status==="A"?"Active":"Inactive",
            date:moment(item.updated_at).format("MM-DD-YYYY")
          })
        }));
      }
    } catch (err) {}
    finally{
      setLoader(false)
    }
  };

  useEffect(() => {
    getSubscriptionList();
  }, []);
  return (
    <div style={{ padding: "10px", borderRadius: "8px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2>Subscriptions Management</h2>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>
          Add Subscription
        </Button>
      </div>

      <Table dataSource={dataSource} columns={columns} loading={loader} rowKey="id"/>

      <Modal
        title={editingRecord ? "Edit Subscription" : "Add Subscription"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        confirmLoading={btnLoader}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                type: "number",
                message: "Please input the price!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} min={0} step={1} />
          </Form.Item>
          <Form.Item
            name="tokens"
            label="Token Count"
            rules={[
              {
                required: true,
                type: "number",
                message: "Please input the token count!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} min={0} step={1} />
          </Form.Item>
      
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please select the status!" }]}
          >
            <Select>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description!" }]}
            
          >
            <ReactQuill theme="snow" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Subscription;
