import React, { useEffect, useState } from "react";
import { Table, Tag, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Request from "../../common/Request";

const Payment = () => {
  const [payments, setPayments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredPayments, setFilteredPayments] = useState(payments);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const res = await Request({
        method: "get",
        url: "superuser/user-subscription",
      });
      const data = res.data.map((item) => {
        return {
          username: `${item.user.first_name} ${item.user.last_name}`,
          transactionId: item.transaction_id,
          paymentDate: "2024-08-26",
          amountPaid: item.amount,
          subscriptionName: item?.subscription.name || "",
          paymentStatus: item.status.toUpperCase(),
        };
      });
      setPayments(data);
      setFilteredPayments(data);
    } catch (error) {
      console.error("Failed to fetch payments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const filteredData = payments.filter(
      (item) =>
        item.username.toLowerCase().includes(value.toLowerCase()) ||
        item.transactionId.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPayments(filteredData);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
    },
    {
      title: "Subscription",
      dataIndex: "subscriptionName",
      key: "subscriptionName",
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (text, record) => `${text} €`,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
  ];

  return (
    <div>
      <Space
        style={{
          marginBottom: 16,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h2>Payments</h2>
        <Input
          placeholder="Search by Name or Transaction ID"
          value={searchText}
          onChange={handleSearch}
          suffix={<SearchOutlined />}
          style={{ width: 260 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredPayments}
        loading={loading}
        rowKey="transactionId"
      />
    </div>
  );
};

export default Payment;
