import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Checkbox, Modal, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Request from '../../common/Request';

const Login = () => {
  const navigate = useNavigate();
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loader, setLoader] = useState(false);

  const handleLogin = async (values) => {
    setLoader(true);
    try {
      const res = await Request({ method: "post", url: "superuser/login/", data: values });
      localStorage.setItem('token', res.data.access);
      localStorage.setItem('admin-data', JSON.stringify(res.data));
      message.success(res.details);
      navigate('/dashboard');
    } catch (err) {
      console.log("err",err);
      message.error(err.details||"Invalid Credentials");
    } finally {
      setLoader(false);
    }
  };

  const handleForgotPassword = () => {
    setForgotPasswordVisible(true);
  };

  const handleCancelForgotPassword = () => {
    setForgotPasswordVisible(false);
    setEmail('');
    setEmailError('');
  };

  const handleSendPasswordReset = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address!');
      return;
    }

    // Call API to send OTP to the email address
    try {
      await Request({ method: "post", url: "superuser/forget-password-otp/", data: { email } });
      setForgotPasswordVisible(false);
      setOtpVisible(true);
    } catch (err) {
      setEmailError('Failed to send password reset link!');
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError('Please enter the OTP sent to your email!');
      return;
    }
      setOtpVisible(false);
      setResetPasswordVisible(true);
  
  };

  const handleResetPassword = async () => {
    if (!newPassword || !confirmPassword) {
      setPasswordError('Please fill out both fields!');
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match!');
      return;
    }

    // Call API to reset password
    try {
      await Request({ method: "post", url: "superuser/verify-otp-and-reset-password/", data: {
        "email":email,
        "otp": otp,
        "new_password": newPassword
      } });
      setResetPasswordVisible(false);
      message.success('Password has been reset successfully!');
    } catch (err) {
      setPasswordError(err?.details||"Failed to Reset password!");
    }
    finally{
      setOtp('')
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div style={{ width: '400px', padding: '30px', background: '#fff', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <h2 style={{ textAlign: 'center' }}>Login</h2>
        <Form onFinish={handleLogin}>
          <Form.Item
            name="email"
            placeholder="Enter your email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not a valid email!' },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password prefix={<LockOutlined />} placeholder="Password" size="large" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Checkbox>Remember me</Checkbox>
              <Button type="link" onClick={handleForgotPassword}>Forgot password?</Button>
            </div>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loader}>Login</Button>
          </Form.Item>
        </Form>
      </div>

      <Modal
        title="Forgot Password"
        visible={forgotPasswordVisible}
        onCancel={handleCancelForgotPassword}
        footer={[
          <Button key="back" onClick={handleCancelForgotPassword}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSendPasswordReset}>
            Send
          </Button>,
        ]}
      >
        <p>Please enter your email address to receive a otp:</p>
        <Input
          placeholder="Email Address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError('');
          }}
        />
        {emailError && <div style={{ color: 'red', marginTop: '8px' }}>{emailError}</div>}
      </Modal>

      <Modal
        title="Enter OTP"
        visible={otpVisible}
        onCancel={() => setOtpVisible(false)}
        footer={[
          <Button key="back" onClick={() => setOtpVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleVerifyOtp}>
            Verify OTP
          </Button>,
        ]}
      >
        <p>Please enter the OTP sent to your email:</p>
        <Input
          placeholder="OTP"
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
            setOtpError('');
          }}
        />
        {otpError && <div style={{ color: 'red', marginTop: '8px' }}>{otpError}</div>}
      </Modal>

      <Modal
        title="Reset Password"
        visible={resetPasswordVisible}
        onCancel={() => setResetPasswordVisible(false)}
        footer={[
          <Button key="back" onClick={() => setResetPasswordVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleResetPassword}>
            Reset Password
          </Button>,
        ]}
      >
        <p>Please enter your new password:</p>
        <Form>
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: 'Please input your new password!' },
            ]}
          >
            <Input.Password
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordError('');
              }}
              style={{ marginBottom: 10 }}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: 'Please confirm your new password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPasswordError('');
              }}
            />
          </Form.Item>
          {passwordError && <div style={{ color: 'red', marginTop: '8px' }}>{passwordError}</div>}
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
