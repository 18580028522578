import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import Request from '../../common/Request';
import { useNavigate } from 'react-router-dom';

const ProfileModal = ({ visible, onClose ,userData,fetchUserData}) => {
  const navigate=useNavigate()
  const [form] = Form.useForm();
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [loader, setLoader] = useState(false)
  // const userData = JSON.parse(localStorage.getItem("admin-data"))
  const handleResetPassword = () => {
    setIsPasswordModalVisible(true);
  };
  const resetPassword = async (values) => {
    setLoader(true)
    try {
      const res = await Request({ method: "post", url: "superuser/reset_password/", data: values })
      console.log(res)
      message.success(res?.details)
      localStorage.setItem('token',"")
      navigate('/'); 
      setIsPasswordModalVisible(false)

    }
    catch (err) {
      console.log(err)
      message.error(err.details)
    }
    finally {
      setLoader(false)
    }
  }
  const handlePasswordOk = () => {
    form.validateFields(['current_password', 'new_password', 'confirm_new_password'])
      .then(async (values) => {
        resetPassword(values)
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handlePasswordCancel = () => {
    setIsPasswordModalVisible(false);
  };
   const handleProfileUpdate = async (values) => {
    try {
      const res = await Request({ method: "put", url: "superuser/my-profile/", data: values });

      message.success(res?.details);
      fetchUserData()
      onClose();

    } catch (err) {
      message.error(err?.details || "Profile update failed");
    }
  };

  const handleProfileSubmit = () => {
    form.validateFields(['first_name', 'last_name'])
      .then((values) => {
        handleProfileUpdate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
      });
    }
  }, [form, userData]);

  return (
    <>
      <Modal
        title="Profile"
        visible={visible}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{}}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email ID"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" onClick={handleProfileSubmit}>
              Update
            </Button>
            <Button type="link" onClick={handleResetPassword} style={{ float: 'right' }}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Reset Password"
        visible={isPasswordModalVisible}
        onOk={handlePasswordOk}
        onCancel={handlePasswordCancel}
        okText="Confirm"
        confirmLoading={loader}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="current_password"
            label="Old Password"
            rules={[{ required: true, message: 'Please input your old password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_new_password"
            label="Confirm Password"
            dependencies={['new_password']}
            rules={[
              { required: true, message: 'Please confirm your new password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProfileModal;
