import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const NonAuthLayout = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Outlet />
    </Content>
  </Layout>
);

export default NonAuthLayout;
