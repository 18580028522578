import React, { useEffect, useState } from "react";
import { Button, Form, message, Spin, Select } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Styles/AboutUsForm.css";
import Request from "../../common/Request";

const { Option } = Select;

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
    ["clean"], // Remove formatting button
  ],
};

const Terms = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [allLanguages, setAllLanguages] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);

  const onFinish = async (values) => {
    console.log(values);
    setBtnLoader(true);
    try {
      const selectedLanguage = allLanguages.find(
        (lang) => lang.lang_name === values.language
      );

      await Request({
        method: "post",
        url: "superuser/terms&condition/",
        data: {
          terms_and_conditions: values.terms_and_conditions,
          language: selectedLanguage ? selectedLanguage.id : null,
        },
      });

      message.success("Terms & Conditions saved!");
    } catch (err) {
      message.error("Something went wrong!");
    } finally {
      setBtnLoader(false);
    }
  };

  const handleQuillChange = (value) => {
    form.setFieldsValue({ terms_and_conditions: value });
  };

  const fetchTermsData = async (langId) => {
    try {
      const res = await Request({
        method: "get",
        url: "superuser/terms&condition/",
        params: { language: langId },
      });

      if (res?.data?.terms_and_conditions) {
        form.setFieldsValue({ terms_and_conditions: res.data.terms_and_conditions });
      } else {
        form.setFieldsValue({ terms_and_conditions: "" });
      }
    } catch (err) {
      message.error("Failed to fetch Terms & Conditions data");
    } finally {
      setLoader(false);
    }
  };

  const getLanguages = async () => {
    setLoader(true);
    try {
      const languageRes = await Request({
        method: "get",
        url: "superuser/language/",
      });

      setAllLanguages(languageRes.data);

      // Set default language as English if available
      const englishLanguage = languageRes.data.find(
        (lang) => lang.lang_name === "English"
      );

      if (englishLanguage) {
        setSelectedLanguageId(englishLanguage.id);
        form.setFieldsValue({ language: englishLanguage.lang_name });
        fetchTermsData(englishLanguage.id); // Fetch terms data for default language
      }
    } catch (err) {
      message.error("Failed to fetch language data");
    } 
  };

  const handleLanguageChange = (value) => {
    const selectedLanguage = allLanguages.find(
      (lang) => lang.lang_name === value
    );
    if (selectedLanguage) {
      setSelectedLanguageId(selectedLanguage.id);
      setLoader(true)
      fetchTermsData(selectedLanguage.id);
    }
  };

  useEffect(() => {
    getLanguages();
    return () => {
      form.resetFields(["terms_and_conditions", "language"]);
    };
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <h2>Terms & Conditions</h2>
      {loader ? (
        <Spin />
      ) : (
        <>
          {/* Language Dropdown */}
          <Form.Item
            label="Language"
            name="language"
            rules={[{ required: true, message: "Please select a language!" }]}
          >
            <Select
              placeholder="Select a language"
              onChange={handleLanguageChange}
              value={form.getFieldValue("language")}
            >
              {allLanguages.map((lang) => (
                <Option key={lang.id} value={lang.lang_name}>
                  {lang.lang_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Terms & Conditions Content */}
          <Form.Item
            name="terms_and_conditions"
            rules={[
              { required: true, message: "Please input the terms & conditions!" },
            ]}
          >
            <div className="editor-container">
              <ReactQuill
                value={form.getFieldValue("terms_and_conditions")}
                onChange={handleQuillChange}
                modules={modules}
                placeholder="Enter the content of terms & conditions"
              />
            </div>
          </Form.Item>

          {/* Submit Button */}
          <Form.Item style={{ marginTop: "10px" }}>
            <Button type="primary" htmlType="submit" loading={btnLoader}>
              Submit
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default Terms;
