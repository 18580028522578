
import './App.css';
import RouteCompnent from './Routes';




function App() {
  return (
    <RouteCompnent />
  );
}

export default App;
