
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../layout/AuthGuard'
import NonAuthGuard from '../layout/NonAuthGuard'
import Login from '../pages/NonAuthPages/Login'
import Dashboard from '../pages/AuthPages/Dashboard'
import MainLayout from '../layout/MainLayout'
import NonAuthLayout from '../layout/NonAuthLayout'
import Faq from '../pages/AuthPages/Faq'
import AboutUs from '../pages/AuthPages/AboutUs'
import ContactUs from '../pages/AuthPages/ContactUs'
import PrivacyPolicy from '../pages/AuthPages/PrivacyPolicy'
import Terms from '../pages/AuthPages/Terms'
import Subscription from '../pages/AuthPages/Subscription'
import Payment from '../pages/AuthPages/Payment'

const RouteCompnent = () => {
    return (
        <Routes>
            <Route
                element={
                    <NonAuthGuard>
                        <NonAuthLayout />
                    </NonAuthGuard>
                }>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
            </Route>
            <Route
                element={
                    <AuthGuard>
                        <MainLayout />
                    </AuthGuard>
                }>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/privacy&policy" element={<PrivacyPolicy />} />
                <Route path="/terms&conditions" element={<Terms />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/payments" element={<Payment />} />
            </Route>

        </Routes>
    )
}

export default RouteCompnent
