

import { Navigate} from 'react-router-dom';

export default function AuthGuard({ children }) {


    const access_token = localStorage.getItem("token")

  if (!access_token) {
    return <Navigate to='/' />;
  }

  return children;
}