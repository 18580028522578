import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Request from "../../common/Request";
import moment from "moment";
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};
const ContactUs = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => `${capitalizeWords(record.first_name)} ${capitalizeWords(record.last_name)}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
   
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => moment(date).format('MMMM Do YYYY, h:mm a'),
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => (
        <span>
          {text.length > 30 ? `${text.slice(0, 30)}... ` : text} 
          {text.length > 30&&<InfoCircleOutlined
            style={{ color: '#1890ff', cursor: 'pointer' ,marginLeft:"5px"}}
            onClick={() => showModal(text)}
          />}
        </span>
      ),
    },
  ];

  const showModal = (message) => {
    setCurrentMessage(message);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const getContactUsDetails = async () => {
    setLoader(true);
    try {
      const res = await Request({ method: "get", url: "superuser/admin-contact-us/" });
      setDataSource(res.data);
    } catch (err) {
      // Handle error appropriately
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getContactUsDetails();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h2>Contact Us</h2>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 8 }}
        loading={loader}
      />

      <Modal
        title="Message Details"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <p>{currentMessage}</p>
      </Modal>
    </div>
  );
};

export default ContactUs;
