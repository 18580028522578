import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  message,
  Spin,
  Select,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Styles/AboutUsForm.css";
import Request from "../../common/Request";

const { Option } = Select;

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
    ["clean"], // Remove formatting button
  ],
};

const AboutUs = () => {
  const [form] = Form.useForm();
  const [imageUrls, setImageUrls] = useState({
    body_image1: "",
    body_image2: "",
  });
  const [fileUploaded, setFileUploaded] = useState({
    body_image1: false,
    body_image2: false,
  });
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [allLanguages, setAllLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const onFinish = async (values) => {
    setBtnLoader(true);
    const {
      top_heading: heading,
      top_subHeading: subheading,
      body_heading,
      body_details,
      body_image1,
      body_image2,
      language,
    } = values;

    if (!fileUploaded.body_image1 && !imageUrls.body_image1) {
      form.setFields([
        { name: "body_image1", errors: ["Image 1 is required if not uploaded."] },
      ]);
      setBtnLoader(false);
      return;
    }
    if (!fileUploaded.body_image2 && !imageUrls.body_image2) {
      form.setFields([
        { name: "body_image2", errors: ["Image 2 is required if not uploaded."] },
      ]);
      setBtnLoader(false);
      return;
    }


    const formData = new FormData();
    formData.append("subheading", subheading);
    formData.append("heading", heading);
    formData.append("body_heading", body_heading);
    formData.append("body_details", body_details);
    formData.append("language", language === "German" ? 2 : 1);

    if (fileUploaded.body_image1) {
      formData.append("body_image1", body_image1);
    }
    if (fileUploaded.body_image2) {
      formData.append("body_image2", body_image2);
    }

    try {
      const res = await Request({
        method: "post",
        url: "superuser/about-us/",
        data: formData,
      });
      message.success(res.details);
      setFileUploaded({
        body_image1: false,
        body_image2: false,
      });
    } catch (err) {
      message.error(err?.details || "Something went wrong");
    } finally {
      setBtnLoader(false);
    }
  };

  const handleQuillChange = (value) => {
    form.setFieldsValue({ body_details: value });
  };

  const handleFileChange = (file, field) => {
    setFileUploaded({ ...fileUploaded, [field]: true });
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrls((prevUrls) => ({
        ...prevUrls,
        [field]: reader.result,
      }));
      form.setFieldsValue({ [field]: file });
    };
    reader.onerror = () => {
      message.error("File reading failed!");
    };
    reader.readAsDataURL(file);
    return false; // Prevent automatic upload
  };

  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      // Handle successful upload if needed
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleDelete = (field) => {
    setImageUrls((prevUrls) => ({
      ...prevUrls,
      [field]: "",
    }));
    form.setFieldsValue({ [field]: "" });
    setFileUploaded({ ...fileUploaded, [field]: false });
  };

  const fetchAboutUsData = async (languageId) => {
    setLoader(true);
    try {
      const aboutUsRes = await Request({
        method: "get",
        url: `superuser/about-us?language=${languageId}`,
      });
      const {
        subheading: top_subHeading,
        heading: top_heading,
        body_heading,
        body_details,
        body_image1,
        body_image2,
      } = aboutUsRes.data[0];

      form.setFieldsValue({
        top_heading,
        top_subHeading,
        body_heading,
        body_details,
        language:
          aboutUsRes.data.length !== 0
            ? aboutUsRes.data[0]?.language?.lang_name || "English"
            : "English",
      });

      setImageUrls({
        body_image1,
        body_image2,
      });
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const getAllLanguages = async () => {
    try {
      const languageRes = await Request({
        method: "get",
        url: "superuser/language/",
      });
       setAllLanguages(languageRes.data);
       let defaultLanguage=languageRes.data.find((item)=>item.lang_code==="en")
       setSelectedLanguage(defaultLanguage?.id??1)
       fetchAboutUsData(defaultLanguage?.id??1); // Fetch data for default language
    } catch (err) {
      message.error("Failed to fetch languages");
    }
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    fetchAboutUsData(value); // Fetch data when language changes
  };

  useEffect(() => {
    getAllLanguages();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: "800px", margin: "auto" }}
    >
      <h2>About Us Page</h2>
      {loader ? (
        <Spin />
      ) : (
        <>
          {/* Language Dropdown */}
          <Form.Item
            label="Language"
            name="language"
            rules={[{ required: true, message: "Please select a language!" }]}
          >
            <Select
              placeholder="Select a language"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              {allLanguages.map((lang) => (
                <Option key={lang.id} value={lang.id}>
                  {lang.lang_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Rest of the form fields */}
          <Form.Item
            label="Top Heading"
            name="top_heading"
            rules={[
              { required: true, message: "Please input the top heading!" },
            ]}
          >
            <Input placeholder="Enter the top heading" />
          </Form.Item>

          <Form.Item
            label="Top Subheading"
            name="top_subHeading"
            rules={[
              { required: true, message: "Please input the top subheading!" },
            ]}
          >
            <Input placeholder="Enter the top subheading" />
          </Form.Item>

          <Form.Item
            label="Body Heading"
            name="body_heading"
            rules={[
              { required: true, message: "Please input the body heading!" },
            ]}
          >
            <Input placeholder="Enter the body heading" />
          </Form.Item>

          <Form.Item
            label="Body Details"
            name="body_details"
            rules={[
              { required: true, message: "Please input the body details!" },
            ]}
          >
            <div className="editor-container">
              <ReactQuill
                value={form.getFieldValue("body_details")}
                onChange={handleQuillChange}
                modules={modules}
                placeholder="Enter the body details"
              />
            </div>
          </Form.Item>

          <Form.Item label="Body Images">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="body_image1" noStyle>
                  <Upload
                    beforeUpload={(file) =>
                      handleFileChange(file, "body_image1")
                    }
                    listType="picture"
                    maxCount={1}
                    showUploadList={false}
                    onChange={handleUploadChange}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image 1</Button>
                  </Upload>
                </Form.Item>
                {imageUrls.body_image1 && (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      src={imageUrls.body_image1}
                      alt="Image 1"
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "10px",
                      }}
                    />
                    <Button
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete("body_image1")}
                    />
                  </div>
                )}
              </Col>
              <Col span={12}>
                <Form.Item name="body_image2" noStyle>
                  <Upload
                    beforeUpload={(file) =>
                      handleFileChange(file, "body_image2")
                    }
                    listType="picture"
                    maxCount={1}
                    showUploadList={false}
                    onChange={handleUploadChange}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image 2</Button>
                  </Upload>
                </Form.Item>
                {imageUrls.body_image2 && (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      src={imageUrls.body_image2}
                      alt="Image 2"
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "10px",
                      }}
                    />
                    <Button
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete("body_image2")}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={btnLoader}
              style={{ marginTop: "20px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default AboutUs;
