import React from 'react';
import { Modal, Button } from 'antd';

const SessionExpiredModal = ({ visible, onLogout }) => {
  return (
    <Modal
      title="Session Expired"
      visible={visible}
      footer={null}
      closable={false} // Make it non-closable
    >
      <p>Your session has expired. Please log in again.</p>
      <Button type="primary" onClick={onLogout}>
        Login Again
      </Button>
    </Modal>
  );
};

export default SessionExpiredModal;
