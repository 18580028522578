import axios from 'axios';
import { base_url } from '.';


  
const axiosInstance = axios.create({
  baseURL:  `${base_url}/api`,
  timeout: 10000, 
  headers: {
    'content-type': 'multipart/form-data',
  },
});


export const Request = async({ method, url='', data = null,params=null })=> {
 
  const la_token=localStorage.getItem("token")||null
  const headers = la_token ? { Authorization: `Bearer ${la_token}` } : {};
  const config={
      method,
      url,
      headers,
      ...(method === 'get' ? { params } : { data }),
   }
   console.log(config)
  try { 
    const response = await axiosInstance.request(config);
    return response.data;
  } catch (error) {
    console.error(`Error during ${method.toUpperCase()} request to ${url}:`, error.response.data);
    throw error.response.data;
  }
};

export default Request;